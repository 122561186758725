import React, { useState } from "react"

import { useShopify, useShopifyVariants } from "../../hooks/useShopify"

export const withProduct = Component => ({ name = "Product", product, content, template, live, loading, productId, sanityProduct }) => {
  const { edgeNormaliser, productNormaliser } = useShopify()

  const [sizeGuideActive, setSizeGuideActive] = useState(false)

  const item = productNormaliser(live?.product || product)
  item.storefrontId = item.id

  const siblings = edgeNormaliser(live?.grouped)

  const multipleSiblings = siblings.length > 0

  const multipleVariants = item?.variants.length > 1

  const { localActiveVariant, selectedOptions, handleVariant } = useShopifyVariants({
    useParameter: false,
    product: item,
    loading,
    noDefaultSelectedVariant: multipleVariants,
    firstAvailable: !multipleVariants,
  })

  const sanityProductParsed = JSON.parse(sanityProduct)

  const media =
    (product?.images?.length &&
      product?.images?.map?.(image => {
        return {
          mediaContentType: "IMAGE",
          __typename: "MediaImage",
          image: {
            altText: image?.altText,
            id: image?.id,
            originalSrc: image,
            transformedSrc: image,
            __typename: "Image",
          },
        }
      })) ||
    (sanityProductParsed?.media?.length &&
      sanityProductParsed?.media?.map?.((mediaItem, index) => ({ ...mediaItem, image: sanityProductParsed.images[index] }))) ||
    edgeNormaliser(item?.media)

  const { settingShowInstagramFeed, settingShowRelatedProducts, sizeConversions } = template

  const { related: relatedProducts, collection: relatedCollection, hideGender } = edgeNormaliser(content)[0]

  const recommendations = live?.recommendations

  const gender = item?.tags?.find(tag => tag.includes("gender"))?.split(":")[1]

  const [showReviews, setShowReviews] = React.useState(true)

  // React.useEffect(() => {
  //   const inter = setInterval(() => {
  //     const okeData = document.querySelectorAll("[data-oke-metafield-data]")
  //     const script = okeData[0]
  //     if (script) {
  //       const json = JSON.parse(script.innerHTML)
  //       if (json.reviewCount) {
  //         setShowReviews(true)
  //         clearInterval(inter)
  //       }
  //     }
  //   }, 1000)

  //   return () => clearInterval(inter)
  // }, [])

  React.useEffect(() => {
    // redirect if gift card giftcard is in the title
    if (item?.title?.toLowerCase()?.replace(/ /g, "")?.includes("giftcard")) {
      window.location.href = "/giftcard"
    }
  }, [item])

  Component.displayName = name

  return (
    <Component
      loading={loading}
      product={item}
      media={media}
      siblings={siblings}
      spec={product.spec}
      gender={gender}
      showReviews={showReviews}
      handleVariant={handleVariant}
      selectedOptions={selectedOptions}
      selectedVariant={localActiveVariant}
      defaultVariant={item.variants?.[0]}
      multipleSiblings={multipleSiblings}
      multipleVariants={multipleVariants}
      settingShowInstagramFeed={settingShowInstagramFeed}
      settingShowRelatedProducts={settingShowRelatedProducts}
      sizeConversions={sizeConversions}
      relatedProducts={relatedProducts}
      relatedCollection={relatedCollection}
      recommendations={recommendations}
      sizeGuideActive={sizeGuideActive}
      setSizeGuideActive={setSizeGuideActive}
      hideGender={hideGender}
      productId={productId}
    />
  )
}
